import {handleActions} from 'redux-actions';
import _ from 'lodash';
import getActions from '../actions/GET-actions';

let GetParams = window.location.search.replace('?','')
    .split('&')
    .reduce(
        (result, param) => {
            let operand = param.split('=');

            if (operand[0]) {
                result[decodeURIComponent(operand[0])] = decodeURIComponent(operand[1]);
            }

            return result;
        },
        {}
    );


const allowedPaymentTypes = [
    {
        id: 'cashless',
        name: 'Оплата Online',
    },
    {
        id: 'cash',
        name: 'Оплата в салоне',
    }
];

export default handleActions({
        [getActions.setRegion]: (state, action) => {
            return {
                ...state,
                region: action.payload
            };
        },
        [getActions.setFacility]: (state, action) => {
            return {
                ...state,
                facility: action.payload
            }
        },
        [getActions.setLang]: (state, action) => {
            return {
                ...state,
                lang: action.payload
            }
        },
        [getActions.setPsh]: (state, action) => {
            return {
                ...state,
                psh: action.payload
            }
        },
        [getActions.setPerekrestok]: (state, action) => {
            return {
                ...state,
                perekrestok: action.payload
            }
        },
        [getActions.setDiscount]: (state, action) => {
            return {
                ...state,
                discount: action.payload
            }
        },
        [getActions.resetAllowedPaymentTypes]: (state) => {
            return {
                ...state,
                allowedPaymentTypes: allowedPaymentTypes
            }
        },
        [getActions.setAllowedPaymentTypes]: (state, action) => {
            return {
                ...state,
                allowedPaymentTypes: action.payload
            }
        },
        [getActions.disablePaymentType]: (state, action) => {
            return {
                ...state,
                allowedPaymentTypes: _.remove(state.allowedPaymentTypes, function(paymentType) {
                    return paymentType.id === action.payload;
                })
            }
        },
        [getActions.addAllowedPaymentType]: (state, action) => {
            return {
                ...state,
                allowedPaymentTypes: [
                    ...state.allowedPaymentTypes,
                    action.payload
                ]
            }
        },
        [getActions.setGuest]: (state, action) => {
            return {
                ...state,
                guest: action.payload
            }
        },
        [getActions.setPartner]: (state, action) => {
            return {
                ...state,
                partner: action.payload
            }
        },
        [getActions.setComment]: (state, action) => {
            return {
                ...state,
                comment: action.payload
            }
        },
        [getActions.setApiKeyNumber]: (state, action) => {
            return {
                ...state,
                apiKeyNumber: action.payload
            }
        },
        [getActions.setUtmSource]: (state, action) => {
            return {
                ...state,
                utmSource: action.payload
            }
        },
        [getActions.setUtmMedium]: (state, action) => {
            return {
                ...state,
                utmMedium: action.payload
            }
        },
        [getActions.setUtmCampaign]: (state, action) => {
            return {
                ...state,
                utmCampaign: action.payload
            }
        },
        [getActions.setUtmTerm]: (state, action) => {
            return {
                ...state,
                utmTerm: action.payload
            }
        },
        [getActions.setUtmContent]: (state, action) => {
            return {
                ...state,
                utmContent: action.payload
            }
        },
    },
    {
        region: process.env.REACT_APP_REGION,
        psh: false,
        perekrestok: false,
        discount: null,
        allowedPaymentTypes: allowedPaymentTypes,
        guest: null,
        partner: {
            value: '',
            found: false,
        },
        comment: null,
        apiKeyNumber: GetParams.akn ? GetParams.akn : 1,
        utmSource: null,
        utmMedium: null,
        utmCampaign: null,
        utmTerm: null,
        utmContent: null,
    });
