import React from 'react';
import Header from "../common/header/Header";
import Container from "react-bootstrap/Container";
import {withRouter} from 'react-router';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import './Registration.scss';
import {connect} from 'react-redux';
import {
	getReferralPartners,
	confirmationPhone,
	checkCode,
} from "../../services/rest";
import ReactPhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import visitAction from '../../actions/visit-actions';
import getAction from '../../actions/GET-actions';
import {withTranslation} from "react-i18next";
import * as cn from 'classnames';
import Spinner from "react-bootstrap/Spinner";
import * as moment from "moment";

class RegistrationPage extends React.Component {
	state = {
		guest: {
			name: null,
			phone: '',
			email: null,
			agreementPolicy: false,
		},
		partner: {
			value: '',
			found: true,
		},
		search: false,
		comment: null,
		availabilityButtonOrder: true,
		errorCreateVisit: false,
		confirmPhone: {
			sendCode: false,
			checkCode: false,
			code: null,
			time: null,
		},
		czechRegion: "2e2a4869-5aae-4abe-bcf0-8a8d886a9ea9",
		loading: false,
	}

	render() {
		const { name, phone, email } = this.state.guest;
		const { sendCode, checkCode, code, time } = this.state.confirmPhone;
		const { comment, partner, search, availabilityButtonOrder, errorCreateVisit, czechRegion, guest, loading } = this.state;
		const { t, region } = this.props;

		return(
			<Container>
				<Header/>
				<Row >
					<Col md={{span:6, offset: 3}}>
						{loading ?
							<span>Загрузка...</span>
						:
							<Form className={'formRegistration'}>
								{
									errorCreateVisit ? <div className={'errorText'}> Ошибка создания визита</div> : null
								}
								<Form.Control
									value={name ? name : ''}
									className={'formRegistration__input'}
									placeholder={t('Name')}
									onChange={this.handleChangeName}
									name="name"
								/>
								<ReactPhoneInput
									placeholder={t('Phone')}
									defaultCountry="cz"
									inputClass={'formRegistration__input'}
									containerStyle={{marginTop: '20px'}}
									buttonClass={'formRegistration__btnRegion'}
									onChange={this.handleChangePhone}
									value={phone}
									name="phone"
								/>
								<Form.Control
									value={email ? email : ''}
									className={'formRegistration__input'}
									placeholder={t('E-mail')}
									onChange={this.handleChangeEmail}
									name="email"
								/>
								<Form.Control
									value={comment ? comment : ''}
									className={'formRegistration__input'}
									placeholder={t('Comment')}
									onChange={this.handleChangeComment}
									name="comment"
								/>
								<div className={'referralPartnerBlock'}>
									<Form.Control
										value={ partner.value }
										className={cn('formRegistration__input','referralPartnerBlock__input', {formRegistration__input_notFound: !partner.found})}
										placeholder={t('Referral partner')}
										onChange={this.handleChangePartner}
									/>
									<div className={cn('formRegistration__alert', {formRegistration__alert_notFound: !partner.found})}>
										{t('not found')}
									</div>
									<Spinner
										animation="border"
										className={cn('referralPartnerBlock__spinner', {referralPartnerBlock__spiner_search: search})}
										variant="primary"
									/>
								</div>
								<div className={'policyBlock'}>
									<Form.Group>
										<Form.Check className={'pt-4'}>
											<Form.Check.Input
												isInvalid={!guest.agreementPolicy}
												value={guest.agreementPolicy}
												type="checkbox"
												name="agreementPolicy"
												onChange={this.handleChangeAgreementPolicy}
											/>
											<Form.Check.Label>
												<a target={'_blank'} href='https://tvoitaispa.ru/upload/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0%20%D0%BA%D0%BE%D0%BD%D1%84%D0%B8%D0%B4%D0%B5%D0%BD%D1%86%D0%B8%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D0%B8_%D0%A2%D0%92%D0%9E%D0%99%D0%A2%D0%90%D0%99.pdf'>Я согласен на обработку персональных данных </a>
											</Form.Check.Label>
										</Form.Check>
									</Form.Group>
								</div>
								{ // при отсутствии проверки телефона
									region === czechRegion ?
										<Button
											variant="warning"
											className={'formRegistration__btn'}
											type="button"
											disabled={!(name && phone && guest.agreementPolicy && availabilityButtonOrder)}
											block
											onClick={this.handleClickBtnForm}
										>
											{t('Order')}
										</Button>
										: null
								}
								{ // отправить код подтверждения
									region !== czechRegion  && !sendCode && !checkCode
										?
										<Button
											variant="warning"
											className={'formRegistration__btn'}
											type="button"
											disabled={!(name && phone && guest.agreementPolicy && availabilityButtonOrder)}
											block
											onClick={this.handleConfirmationPhone}
										>
											{t('Confirm phone number')}
										</Button>
										: null
								}
								{ // нужно ввести код подтверждения
									region !== czechRegion && sendCode && !checkCode
										?
										<Form.Group>
											<Form.Label
												className={'formRegistration__label'}
											>
												{t('Time to enter the code')} {time && time.asSeconds()} {t('sec')}
											</Form.Label>
											<Form.Control
												value={code ? code : ''}
												placeholder={t('Verification code')}
												onChange={this.handleChangeCode}
											/>
										</Form.Group>
										: null
								}
								{ //все этапы проверки телефона пройдены
									region && sendCode && checkCode
										?
										<Button
											variant="warning"
											className={'formRegistration__btn'}
											type="button"
											disabled={!(name && phone && guest.agreementPolicy && availabilityButtonOrder)}
											block
											onClick={this.handleClickBtnForm}
										>
											{t('Order')}
										</Button>
										: null
								}
							</Form>
						}
					</Col>
				</Row>
			</Container>
		)
	}

	componentDidMount() {
		if (!this.props.visit.id) {
			this.props.history.push('/');
		}

		if (this.props.visit.guest && this.props.visit.guest.id) {
			this.props.setVisit({});
			this.props.history.push('/');
		}

	}

	handleConfirmationPhone = () => {
		const {guest, confirmPhone} = this.state;

		confirmationPhone(guest.phone)
			.then(response => {
				if (response.success && response.data.result) {
					this.setState({
						confirmPhone: {
							...confirmPhone,
							sendCode: true
						}
					}, this.timer)
				}
			})
	}

	timer = () => {
		const { confirmPhone } = this.state;
		let time;

		if (confirmPhone.time === null) {
			time = moment.duration(120, 'seconds');
		} else {
			if (confirmPhone.time.asSeconds() <= 0) {
				this.setState({
					confirmPhone: {
						...confirmPhone,
						sendCode: false,
						code: null,
						time: null
					}
				})

				return;
			}

			time = confirmPhone.time.subtract(1, 'seconds');
		}

		this.setState({
			confirmPhone: {
				...confirmPhone,
				time
			}
		}, () => {
			setTimeout(this.timer, 1000);
		})
	}

	handleClickBtnForm = () => {
		this.setState({
			availabilityButtonOrder: false
		}, function() {
			const { guest, comment, partner } = this.state

			this.props.setGuest(guest)
			this.props.setComment(comment)
			this.props.setPartner(partner)
			this.props.history.push('/check-unclosed-visits')
		})
	}

	handleChangeName = event => {
		const {guest} = this.state
		let name = event.target.value;

		name = name.length < 251 ? name : name.substr(0, 250);

		this.setState({
			guest: {
				...guest,
				name: name,
			}
		})
	}

	handleChangeCode = event => {
		const { confirmPhone, guest } = this.state;
		let code = event.target.value;

		if (code.search(/\D/) !== -1 || code.length > 4) {
			return;
		}

		if (code.length > 3 && guest.phone){
			checkCode(code, guest.phone)
				.then(response => {
					if (response.success && response.data.result) {
						this.setState({
							confirmPhone: {
								...confirmPhone,
								checkCode: true
							}
						})
					}
				})
		}

		this.setState({
			confirmPhone: {
				...confirmPhone,
				code: code
			}
		})
	}

	handleChangePhone = value => {
		const {guest} = this.state;

		this.setState({
			guest: {
				...guest,
				phone: value,
			}
		})
	}

	handleChangePartner = event => {
		const { partner } = this.state
		let value = event.target.value;

		value = value.length < 256 ? value : value.substr(0, 255);

		this.setState({
			partner: {
				...partner,
				value: value
			}
		})

		if (value !== '') {
			setTimeout((value) => {
					const {partner} = this.state;

					if (value === partner.value) {
						this.setState({
							search: true,
						});

						getReferralPartners(value)
							.then((response) => {
								if (response.success) {
									const {partner} = this.state;

									if (value === partner.value) {
										this.setState({
											partner: {
												...partner,
												found: response.data.found,
											},
											search: false,
										})
									}
								} else {
									this.setState({
										search: false,
									})
								}
							});
					}
				},
				500,
				value
			);
		}

	}

	handleChangeEmail = event => {
		const {guest} = this.state;
		let email = event.target.value;

		email = email.length < 501 ? email : guest.email;

		this.setState({
			guest: {
				...guest,
				email: email,
			}
		})
	}

	handleChangeComment = event => {
		this.setState({
			comment: event.target.value.length < 501 ? event.target.value : this.state.comment
		});
	}

	handleChangeAgreementPolicy = event => {
		this.setState({
			guest: {
				...this.state.guest,
				agreementPolicy: !this.state.guest.agreementPolicy
			}
		})
	}
}

const mapStateToProps = state => ({
	visit: state.visit,
	region: state.GET.region,
	getPsh: state.GET.psh,
	getPerekrestok: state.GET.perekrestok,
	getDiscount: state.GET.discount,
	guest: state.GET.guest,
});

const mapDispatchToProps = {
	setVisit: visitAction.setVisit,
	setGuest: getAction.setGuest,
	setComment: getAction.setComment,
	setPartner: getAction.setPartner
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(RegistrationPage)));