import { createActions } from 'redux-actions';

const actions = createActions({
    SET_REGION: undefined,
    SET_FACILITY: undefined,
    SET_LANG: undefined,
    SET_PSH: undefined,
    SET_PEREKRESTOK: undefined,
    SET_DISCOUNT: undefined,
    RESET_ALLOWED_PAYMENT_TYPES: undefined,
    SET_ALLOWED_PAYMENT_TYPES: undefined,
    DISABLE_PAYMENT_TYPE: undefined,
    ADD_ALLOWED_PAYMENT_TYPE: undefined,
    SET_GUEST: undefined,
    SET_COMMENT: undefined,
    SET_PARTNER: undefined,
    SET_API_KEY_2: undefined,
    SET_UTM_SOURCE: undefined,
    SET_UTM_MEDIUM: undefined,
    SET_UTM_CAMPAIGN: undefined,
    SET_UTM_TERM: undefined,
    SET_UTM_CONTENT: undefined,

});

export default actions;