import { hot } from 'react-hot-loader/root';
import App from './App';
import {Provider} from 'react-redux';
import React from 'react';
import {Router} from 'react-router-dom';
import store from './store';
import {history} from './history';

class Root extends React.Component {

    render() {
        return (
            <Provider store={store}>
                <Router history={history} >
                    <React.Suspense fallback={<div>Loading...</div>}>
                        <App/>
                    </React.Suspense>
                </Router>
            </Provider>
        )
    }
}

export default hot(Root);