import React from 'react';
import Row from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import './calendar.scss';
import Header from "../common/header/Header";
import Calendar from "react-calendar";
import {connect} from "react-redux";
import {withRouter} from 'react-router'
import visitAction from '../../actions/visit-actions';
import moment from 'moment';
import {withTranslation} from "react-i18next";
import {getDatesWithoutMasters} from "../../services/rest";


class CalendarPage extends React.Component {
    state = {
        visitFacility: [],
        disabledDates: [],
        maxDate: null
    }

    render () {
        const {i18n} = this.props;
        const locale = i18n.language;
        const {maxDate} = this.state;

        return(
            <Row>
                <Header/>
                <Col md={{span:6, offset:3}}>
                    <Calendar
                        onChange={this.handleChangeDate}
                        className={'calendarCustom'}
                        tileDisabled={this.handleDisableDate}
                        calendarType={'ISO 8601'}
                        locale={locale}
                        maxDate={maxDate && maxDate.toDate()}
                    />
                </Col>
            </Row>
        )
    }

    componentDidMount() {
        const { visitFacility, parlour } = this.props.visit;
        const today = moment();
        let maxDate = moment().add(30, 'days');

        if (!visitFacility || !Array.isArray(visitFacility) || visitFacility.length < 1) {
            this.props.history.push('/select-facilities');
        }

        getDatesWithoutMasters(today.format('YYYY-MM-DD'), maxDate.format('YYYY-MM-DD'), parlour.id, this.props.perekrestok)
            .then(response => {
                if (response.success) {
                    this.setState({
                        disabledDates: response.data
                    })
                }
            })

        this.setState({
            maxDate
        })
    }

    handleChangeDate = date => {
        let dateVisit = moment.utc(moment(date).format('YYYY-MM-DD'));

        this.props.setDateVisit(dateVisit.format());
        this.props.history.push('/timing-visit');
    }

    handleDisableDate = ({activeStartDate, date, view}) => {
        const { disabledDates } = this.state;

        return (date < moment().startOf('day') || date < moment('04.06.2020 00:00:00')
        || (view === 'month' && disabledDates.some((disabledDate) => {
                let disableDay = new Date(disabledDate.day);
                return date.getFullYear() === disableDay.getFullYear() &&
                date.getMonth() === disableDay.getMonth() &&
                date.getDate() === disableDay.getDate();

            })))
    }
}

const mapDispatchToProps = {
    setDateVisit: visitAction.setDateVisit,
};

const mapStateToProps = state => ({
    visit: state.visit,
    perekrestok: state.GET.perekrestok
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(CalendarPage)));