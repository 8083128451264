import React from 'react';
import Header from "../common/header/Header";
import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import {connect} from 'react-redux';
import Col from "react-bootstrap/Col";
import './successPayParlourPage.scss';
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";
import * as PropTypes from "prop-types";
import {ListGroup} from "react-bootstrap";
import moment from "moment";

class SuccessPayParlourPage extends React.Component {

    static propTypes = {
        visit: PropTypes.object,
        discount: PropTypes.string
    };

    state = {
        title: "Заказ оплачен",
        start: null
    }

    render() {
        const {visit} = this.props;
        const {start} = this.state;
        return (
            <Container>
                <Header
                    title={this.state.title}
                />
                <Row>
                    <Col md={{span:6, offset: 3}}>
                        <h5 className={'mt-4'}>Спасибо что оформли визит, ждём вас!</h5>
                        <ListGroup variant="flush" className={"mb-4"}>
                            {visit && visit.visitFacility ? visit.visitFacility.map(
                                (visitFacility, index) =>
                                    <ListGroup.Item>
                                        {index+1 > 1 ? <b className={'mr-3'}>{index+1}.</b>: null}
                                        <b>{visitFacility.facility.name}</b> <br/>
                                    </ListGroup.Item>) :null
                            }
                        </ListGroup>
                        <Row>
                            {(!this.props.getPsh && this.props.discount && this.props.discount.handler !== 'AmountAsYouWishPerekrestok') ? <Col md={{span: 6}}><h5>К оплате: {visit.totalFormatted}</h5></Col> : null}
                            <Col md={{span: 6}}><h5>Время: {start ? start.format('D.MM.Y HH:mm') : null}</h5></Col>
                            <Col md={{span: 12}}><h5>Адрес: {visit.parlour.address}</h5></Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        )
    }

    componentDidMount() {
        if (!this.props.visit.id) {
            this.props.history.push('/');
        }
        this.setStart();
    }

    setStart() {
        const {visit} = this.props
        const tmp = visit.visitFacility.sort((a, b) => {
            return moment(a.start).isBefore(b.start)
        })
        this.setState({start: moment(tmp[0].start)})
    }
}

const mapStateToProps = state => ({
    visit: state.visit,
    getPsh: state.GET.psh,
    discount: state.GET.discount
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(SuccessPayParlourPage)));