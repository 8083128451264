import {handleActions} from 'redux-actions';
import visitActions from '../actions/visit-actions';

export default handleActions( {
    [visitActions.setParlour]: (state, action) => {
        return {...state, parlour: action.payload};
    },
    [visitActions.setVisitFacility]: (state, action) => {
        return {...state, visitFacility: action.payload};
    },
    [visitActions.setDateVisit]: (state, action) => {
        return {...state, dateVisit: action.payload}
    },
    [visitActions.setDiscountId]: (state, action) => {
        return {...state, discountId: action.payload}
    },
    [visitActions.setTotal]: (state, action) => {
        return {...state, total: {...state.total, val: action.payload}}
    },
    [visitActions.setVisit]: (state, action) => {
        return action.payload
    }
}, {
    parlour: null,
    visitFacility: null,
    dateVisit: null,
    discountId: null,
    total: null,
});