import React from 'react';
import {Col, Row} from "react-bootstrap";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import './header.scss';
import { withRouter } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import visitAction from "../../../actions/visit-actions";

const DEFAULT_LOGO = '479ba7b2-7e56-4cb6-bef8-d632eef2e64b-logo.svg';

class Header extends React.Component {

    static defaultProps = {
        title: null,
    }

    render() {
        const { t, visit, location, title, region } = this.props;
        const logo = region && region === '2e2a4869-5aae-4abe-bcf0-8a8d886a9ea9' ?  region + '-logo.svg' : DEFAULT_LOGO;

        return (
            <React.Fragment>
                <Row className={'contentHeader'}>
                    <Col md={{span:6, offset: 3}} xs={{span:6, offset: 3}} className={'homeLogo'}>
                        <img
                            className={'homeLogo__img'}
                            src={ '/logo/' + logo }
                            alt=''
                        />
                    </Col>
                    <Col md={3} xs={3} className={'arrowLeft'}>
                        {
                            ['/select-facilities', '/calendar', '/timing-visit', '/select-pay-doc', '/success'].indexOf(location.pathname) !== -1
                                ?
                                <FontAwesomeIcon
                                    icon={'arrow-left'}
                                    className={'arrowLeft__link'}
                                    onClick={this.handleClickBack}
                                />
                                : null
                        }
                    </Col>
                </Row>
                <Row className={'contentTitle'}>
                    <div className={'shade'}></div>
                    <div className={'visitInfo'}>
                        <Col md={12}>
                            <h1 className={'title'}>{title || t('Online registration')}</h1>
                        </Col>
                        {
                            visit && visit.parlour
                                ?
                                    <Col md={12}>
                                        {t('Salon')}: {visit.parlour.displayName ? visit.parlour.displayName : visit.parlour.name}
                                    </Col>
                                : null
                        }
                    </div>
                </Row>
            </React.Fragment>
        )
    }

    handleClickBack = () => {
        if (this.props.history.location.pathname === "/select-facilities") {
            this.props.setVisitFacility(null);
        }
        this.props.history.goBack();
    }
}

const mapDispatchToProps = {
    setVisitFacility: visitAction.setVisitFacility
};

const mapStateToProps = state =>({
    visit: state.visit,
    region: state.GET.region,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(Header)));