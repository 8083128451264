import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './updateVisit.scss';
import Header from "../common/header/Header";
import {connect} from 'react-redux';
import {updateVisit} from '../../services/rest';
import {withRouter} from "react-router";
import visitAction from '../../actions/visit-actions';
import {withTranslation} from "react-i18next";
import '../../styles/main.scss';

class UpdateVisit extends React.Component {
    state = {
        errorCreateVisit: false,
    }
    render() {
        const { t, getPsh } = this.props;
        const { errorCreateVisit } = this.state
        return (
            <Container>
                <Header title={getPsh ? 'Записаться по акции "Плати сколько хочешь"' : null}/>
                <Row className={'updateVisit__container'}>
                    <Col md={{span:6, offset: 3}}>
                        <span className={'content__title'}>{t('Updating data')}</span>
                    </Col>
                    {errorCreateVisit ?
                        <Col md={{span:6, offset: 3}}>
                            <p className={'errorText'}>{t('Failed to update visit details')}</p>
                        </Col>:
                        null
                    }
                </Row>
            </Container>
        );
    }

    componentDidMount() {
        if (this.props.guest) {
            const {guest, comment, partner, visit} = this.props;

            let discountId = null;
            let discountNumber = null;

            if (this.props.getPsh) {
                discountId = '76e48b62-412d-4ba9-af81-9a4a2120b746'
                discountNumber = visit.visitFacility.reduce(function (sum, visitFacility) {
                    return sum + visitFacility.price.val
                }, 0)
            }

            if (this.props.getPerekrestok) {
                discountId = this.props.getDiscount.id
                if (this.props.getDiscount.numberRequired) {
                    discountNumber = visit.visitFacility.reduce(function (sum, visitFacility) {
                        return sum + visitFacility.price.val
                    }, 0)
                }
            }

            let visitFacility

            if (visit.visitFacility) {
                visitFacility = visit.visitFacility.map(visitFacility => {
                        return {
                            ...visitFacility,
                            facility: visitFacility.facility.id ?? visitFacility.facility
                        }
                    }
                )
            }

            let visitRequest = {
                id: visit.id,
                guest: guest,
                comment: comment,
                parlour: visit.parlour.id,
                visitFacility: visitFacility,
                referralPartnerName: partner.value,
                discount: discountId,
                discountNumber: discountNumber
            };

            updateVisit(visitRequest)
                .then(response => {
                    if (response.success) {
                        this.props.setVisit(response.data);
                        if (this.props.getPsh) {
                            this.props.history.push('/success-pay-parlour')
                        } else if (this.props.getPerekrestok) {
                            this.props.history.push('/select-payment')
                        } else {
                            this.props.history.push('/complete');
                        }
                    } else {
                        this.setState({
                            errorCreateVisit: true
                        })
                    }
                })
        } else {
            this.props.history.push('/')
        }
    }
}

const mapStateToProps = state => ({
    visit: state.visit,
    getPsh: state.GET.psh,
    getPerekrestok: state.GET.perekrestok,
    guest: state.GET.guest,
    getDiscount: state.GET.discount,
    partner: state.GET.partner,
    comment: state.GET.comment,
});

const mapDispatchToProps = {
    setVisit: visitAction.setVisit,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(UpdateVisit)));
