import React from 'react';
import {Container, Row, Col, Button, ListGroup} from "react-bootstrap";
import './selectPayment.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {withRouter} from "react-router";
import Header from '../common/header/Header';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";

class SelectPaymentPage extends React.Component {

    componentDidMount() {
        if (!this.props.visit || !this.props.visit.id) {
            this.props.history.push('/');
        }
        if(this.props.discount.handler === 'AmountAsYouWishPerekrestok') {
            this.props.history.push('/success-pay-parlour')
        }
    }

    handleSelectPayment = (event) => {
        if (event.currentTarget.dataset.paymentId === 'cash') {
            this.props.history.push('/success-pay-parlour');
        } else if (event.currentTarget.dataset.paymentId === 'cashless') {
            const {region} = this.props;
            if (region && region !== process.env.REACT_APP_CZECH_REGION) {
                window.open(process.env.REACT_APP_HOST_PAY + this.setLinkPay())
            }
        }
    }

    setLinkPay = (discountId = null) => {
        const {visit, region} = this.props;
        let price = visit.total.val;

        let linkPay = '';
        let params = [
            'from_type',
            'user_name',
            'user_phone',
            'free_price',
            'user_email',
            'visit',
            'discount'
        ];
        const defaultValueLink = '?';

        if (region && region !== process.env.REACT_APP_CZECH_REGION) {
            linkPay = params.reduce((itemLink, param) => {
                let result = itemLink === defaultValueLink ? itemLink : itemLink + '&';

                switch (param) {
                    case 'from_type':
                        result += param + '=visit'
                        break;

                    case 'user_name':
                        result += param + '=' + visit.guest.fullName;
                        break;

                    case 'user_phone':
                        result += param + '=' + visit.guest.phone;
                        break;

                    case 'user_email':
                        result += param + '=' + visit.guest.email;
                        break;

                    case 'free_price':
                        result += param + '=' + price;
                        break;

                    case 'visit':
                        result += param + '=' + visit.id;
                        break;

                    case 'discount':
                        result += param + '=' + discountId;
                        break;

                    default:
                        break;
                }

                return result;
            }, defaultValueLink);
        }

        return linkPay;
    }

    renderPayments = () => {
        const {allowedPaymentTypes} = this.props

        return allowedPaymentTypes.map((payment, key) => {
            return (
                <Col sm={6} className={'mb-4'}>
                    <Button
                        key={key}
                        variant="success"
                        block
                        data-payment-id={(payment.id)}
                        onClick={this.handleSelectPayment}
                    >
                        <FontAwesomeIcon icon={'wallet'}/>
                        <span>{payment.name}</span>
                    </Button>
                </Col>
            )
        })
    }

    render() {
        const {t, visit} = this.props;

        return (
            <Container>
                <Header/>
                <Row className={'content'}>
                    <Col
                        md={{span:6, offset:3}}
                    >
                        <h4 className={'text-center text-uppercase font-weight-bold my-3'}>Заказ оформлен</h4>
                        {
                            visit && visit.visitFacility
                                ? <ListGroup variant="flush" className={'mb-4'}>
                                    {
                                        visit.visitFacility.map((visitFacility, index) => {
                                            return (
                                                <ListGroup.Item key={index} className={'facilitiesVisit__item facility'}>
                                                    {index + 1}. {t(visitFacility.facility.name)}
                                                </ListGroup.Item>
                                            )
                                        })
                                    }
                                </ListGroup>
                                : null
                        }

                        <Col xs={12} className={'d-flex flex-column justify-content-center mb-4'}>
                            <div><b>Цена</b></div>
                            <div><b>{visit.totalFormatted}</b></div>
                        </Col>

                        <Row>
                            {
                                this.renderPayments()
                            }
                        </Row>
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapDispatchToProps = {}

const mapStateToProps = state => ({
    allowedPaymentTypes: state.GET.allowedPaymentTypes,
    visit: state.visit,
    region: state.GET.region,
    discount: state.GET.discount,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(SelectPaymentPage)));