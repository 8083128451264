import React from 'react';
import Header from "../common/header/Header";
import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import {connect} from 'react-redux';
import Col from "react-bootstrap/Col";
import './successPage.scss';
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";
import * as PropTypes from "prop-types";
import {Button, ListGroup} from "react-bootstrap";
import moment from "moment";

class SuccessPage extends React.Component {

    static propTypes = {
        visit: PropTypes.object,
        discount: PropTypes.string
    };

    state = {
        title: "Заказ оплачен",
        start: null
    }

    render() {
        const {visit} = this.props;
        const {start} = this.state;
        return (
            <Container>
                <Header
                    title={this.state.title}
                />
                <Row>
                    <Col md={{span:6, offset: 3}}>
                        <h5 className={'mt-4'}>{visit.guest.name}, Ждём вас!</h5>
                        <ListGroup variant="flush" className={"mb-4"}>
                            {visit && visit.visitFacility ? visit.visitFacility.map(
                                (visitFacility, index) =>
                                    <ListGroup.Item>
                                        {index+1 > 1 ? <b className={'mr-3'}>{index+1}.</b>: null}
                                        <b>{visitFacility.facility.name}</b> <br/>
                                    </ListGroup.Item>) :null
                            }
                        </ListGroup>
                        <Row>
                            <Col md={{span: 6}}><h5>Оплачено: {visit.totalFormatted}</h5></Col>
                            <Col md={{span: 6}}><h5>Время: {start ? start.format('D MMMM HH:mm') : null}</h5></Col>
                        </Row>
                        <Row className={"justify-content-center mt-4"}>
                            <Col md={{span: 6}}>
                                <Button variant="warning" type="button" onClick={this.goHome}>Вернуться в начало</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        )
    }

    componentDidMount() {
        if (!this.props.visit.id) {
            this.props.history.push('/');
        }
        this.setStart();
    }

    setStart() {
        const {visit} = this.props
        const tmp = visit.visitFacility.sort((a, b) => {
            return moment(a.start).isBefore(b.start)
        })
        this.setState({start: moment(tmp[0].start)})
    }

    goHome = () => {
        this.props.history.push('/');
    }
}

const mapStateToProps = state => ({
    visit: state.visit,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(SuccessPage)));