import React from 'react';
import Container from "react-bootstrap/Container";
import Header from "../common/header/Header";
import {createVisit, getTimingVisit} from '../../services/rest';
import {connect} from "react-redux";
import {withRouter} from "react-router";
import visitAction from "../../actions/visit-actions";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './timingVisit.scss';
import moment from 'moment';
import {withTranslation} from "react-i18next";

class TimingVisitPage extends React.Component {
    static defaultProps = {
        visit: {}
    }

    state = {
        timingVisit: [],
        error: null,
    }

    render() {
        const {t} = this.props;
        const {error} = this.state;
        return (
            <Container>
                <Header/>
                <Row className={'timingVisit__container'}>
                    <Col md={{span:6, offset: 3}}>
                        {
                            error ? <div className={'errorText'}> Ошибка создания визита</div> : null
                        }
                        <h1 className={'content__title'}>{t('Choose your visit time')}</h1>
                        {
                            this.renderScheduling()
                        }
                    </Col>
                </Row>
            </Container>
        )
    }

    componentDidMount() {
        const {dateVisit, parlour, visitFacility} = this.props.visit;
        const {region} = this.props;

        let duration = false;
        if(visitFacility) {
            duration = visitFacility.reduce((duration, facility) => {
                return duration + facility.duration
            }, 0);

            if (this.isVisitExist() && duration > 0) {
                duration = duration / 60
            }
        }

        if (parlour && dateVisit && duration) {
            const specializations = [];
            const facilities = [];

            if (this.isVisitExist()) {
                visitFacility.forEach(visitFacility => {
                    if (visitFacility.facility.id) {
                        facilities.push(visitFacility.facility.id)
                    }
                    if (visitFacility.masterSpecializations) {
                        visitFacility.masterSpecializations.forEach(specialization => {
                            if (specializations.indexOf(specialization.id) < 0) {
                                specializations.push(specialization.id);
                            }

                        });
                    }
                });
            } else {
                visitFacility.forEach(visitFacility => {
                    if (visitFacility.masterSpecializations) {
                        visitFacility.masterSpecializations.forEach(specialization => {
                            if (specializations.indexOf(specialization) < 0) {
                                specializations.push(specialization);
                            }
                            facilities.push(visitFacility.facility)
                        });
                    }
                });
           }

            getTimingVisit(parlour.id, dateVisit, duration, specializations, facilities)
                .then(response => {
                    if (response.success) {
                        if(region && region !== process.env.REACT_APP_CZECH_REGION) {
                            for (let key in response.data) {
                                if(
                                    [
                                        '10:00',
                                        '11:30',
                                        '13:00',
                                        '14:30',
                                        '16:00',
                                        '17:30',
                                        '19:00',
                                        '20:30',
                                    ]
                                        .indexOf(key) === -1
                                )
                                    delete response.data[key];
                            }
                        }
                        this.setState({
                            timingVisit: response.data
                        });
                    } else {
                        this.setState({
                            error: response.error.message
                        })
                    }

                })
        } else {
            this.props.history.push('/');
        }
    }

    renderScheduling() {
        const {timingVisit} = this.state;
        let result = [];

        for (let time in timingVisit) {
            result.push(
                <div className={'timingVisit__item timingVisitItem'} key={time}>
                    <div
                        className={'timingVisitItem__content'}
                        onClick={this.handleClickTime(time)}
                    >
                        {time}
                    </div>
                </div>
            );
        }

        return result;
    }

    handleClickTime = (selectedTime) => () => {
        const {timingVisit} = this.state;
        const {visit} = this.props;
        let timeStart = moment(timingVisit[selectedTime].time);
        let {visitFacility} = visit;
        let masters = timingVisit[selectedTime].masters.map(master => {return {id: master}});

        if (this.isVisitExist()) {
            visitFacility = visitFacility.map((visitFacility) => {
                let start = timeStart.format();
                timeStart.add(visitFacility.duration, 'minutes');

                return {
                    id: visitFacility.id,
                    start: start,
                    duration: visitFacility.duration,
                    facility: visitFacility.facility,
                    price: visitFacility.price,
                    priceId: visitFacility.priceId,
                    master: masters
                }
            });

            this.props.setVisit({...visit, visitFacility});
            this.props.history.push('/check-unclosed-visits');
        } else {
            visitFacility = visitFacility.map((visitFacility) => {
                let start = timeStart.format();
                timeStart.add(visitFacility.duration, 'hours');

                return {
                    start: start,
                    duration: visitFacility.duration * 60,
                    price: visitFacility.price,
                    priceId: visitFacility.priceId,
                    facility: visitFacility.facility,
                }
            });

            createVisit({
                parlour: visit.parlour.id,
                masters: masters,
                visitFacility: visitFacility,
                utm: {
                    utm_source: this.props.utmSource,
                    utm_medium: this.props.utmMedium,
                    utm_campaign: this.props.utmCampaign,
                    utm_term: this.props.utmTerm,
                    utm_content: this.props.utmContent,
                }
            }).then(response => {
                if (response.success) {
                    let {visit} = this.props;

                    visit = {
                        ...visit,
                        visitFacility: response.data.visitFacility,
                        id: response.data.id,
                    }

                    this.props.setVisit(visit);
                    this.props.history.push('/registration');
                } else {
                    this.setState({
                        error: response.error.message
                    })
                }
            })
        }
    }

    isVisitExist = () => !!this.props.visit.id
}

const mapStateToProps = state => ({
    visit: state.visit,
    region: state.GET.region,
    utmSource: state.GET.utmSource,
    utmMedium: state.GET.utmMedium,
    utmCampaign: state.GET.utmCampaign,
    utmTerm: state.GET.utmTerm,
    utmContent: state.GET.utmContent,
});

const mapDispatchToProps = {
    setVisit: visitAction.setVisit,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(TimingVisitPage)));