import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from "react-bootstrap/Button";
import './selectFacilities.scss';
import Header from "../common/header/Header";
import {connect} from 'react-redux';
import {getFacilitiesVisit, getMaxDurationMasterLoad} from '../../services/rest';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {withRouter} from "react-router";
import visitAction from '../../actions/visit-actions';
import getAction from '../../actions/GET-actions';
import {currency} from '../common/common';
import {withTranslation} from "react-i18next";
import * as cn from 'classnames';
import * as qs from 'query-string-object';
import moment from "moment";
import '../../styles/main.scss';

class SelectFacilitiesPage extends React.Component {

    static defaultProps = {
        parlour: null,
    }

    state = {
        facilities: [],
        facilitiesDisplay: [],
        parlour: null,
        visit: {
            visitFacility: [],
            sum: 0,
            duration: 0,
        },
        search: null,
        maxDurationMasterLoad: null,
    };


    render() {
        const {facilities, facilitiesDisplay, visit, search, maxDurationMasterLoad} = this.state;
        const {parlour, t, getPsh} = this.props;

        return (
            <Container className={'pageFacility'}>
                <Header title={getPsh ? 'Записаться по акции "Плати сколько хочешь"' : null}/>
                <Row>
                    <Col md={{span: 6, offset: 3}} className={cn('content', 'facilitySearch')}>
                        <input
                            className={'facilitySearch__input'}
                            type={'text'}
                            name={'search'}
                            placeholder={t('Search services')}
                            onChange={this.handleChangeSearch}
                            value={search ? search : ''}
                        />
                    </Col>
                </Row>
                {
                    visit && visit.visitFacility && Array.isArray(visit.visitFacility) && visit.visitFacility.length > 0
                        ?
                        <Row>
                            <Col md={{span: 6, offset: 3}} className={cn('content', 'facilitySelected')}>
                                <div className={'facilitySelected__container'}>
                                    <div className={'facilitySelected__title'}>{t('Selected services')}</div>
                                    {
                                        visit && visit.visitFacility && Array.isArray(visit.visitFacility)
                                            ? visit.visitFacility.map((visitFacility, numberVisitFacility) => {
                                                let facility = facilities.find(facility => facility.id === visitFacility.facility);

                                                return (
                                                    <div className={'facilitySelected__item'} key={numberVisitFacility}>
                                                        {this.renderFacilityName(facility.name, visitFacility.duration)}
                                                        <FontAwesomeIcon
                                                            icon={"times"}
                                                            className={'facilitySelectedItem__iconDelete'}
                                                            onClick={this.handleDeleteVisitFacility(numberVisitFacility)}
                                                        />
                                                    </div>);
                                            })
                                            : null
                                    }
                                </div>
                            </Col>
                        </Row>
                        : null
                }
                <Row className={cn('content', 'facilityList')}>
                    {
                        facilitiesDisplay && Array.isArray(facilitiesDisplay) && facilitiesDisplay.length > 0
                            ? facilitiesDisplay.map((facility, id) => (
                                <Col
                                    key={id}
                                    md={{span: 6, offset: 3}}
                                    className={cn('facilityList__item', 'facilityItem')}
                                    ref={facility.ref}
                                >
                                    <span className={cn('facilityItem__title',)}
                                          onClick={this.handleOpenFacility(id)}>{t(facility.name)}</span>
                                    <FontAwesomeIcon
                                        icon={facility.informationBlockOpen ? 'chevron-down' : "chevron-up"}
                                        className={'facilityItem__arrow'}
                                        onClick={this.handleOpenFacility(id)}
                                    />
                                    <Container className={cn('facilityItem__content', {facilityItem__content_hided: facility.informationBlockOpen})}>
                                    <Row>
                                        <p>{facility.description}</p>
                                    </Row>
                                    {
                                        facility.renderPrice && facility.renderPrice.map((price, numberPrice) => (
                                            <Row className={'facilityItem__block'} key={numberPrice}>
                                                <span
                                                    className={cn('facilityItem__name', 'facilityItemName')}
                                                    onClick={this.handleAddFacility(facility.id, price.id)}
                                                >
                                                    <i className={'facilityItemName__checkbox'}></i>
                                                    {price.name}
                                                </span>
                                                    <span
                                                        className={'facilityItem__price'}>{price.widgetPrice.val} {currency[parlour.mainCurrency]}</span>
                                                </Row>
                                            ))
                                        }
                                    </Container>
                                </Col>
                            ))
                            : null
                    }
                </Row>
                {
                    visit && visit.visitFacility && Array.isArray(visit.visitFacility) && visit.visitFacility.length > 0
                        ?
                        <Row className={cn('container', 'bottomPanel')}>
                            <Col md={6}>
                                <div
                                    className={'bottomPanel__totalServices'}>{t('Services selected')}: {visit.visitFacility.length}</div>
                                <div
                                    className={'bottomPanel__totalSum'}>Сумма: {visit.sum} {currency[parlour.mainCurrency]} </div>
                                <div className={'bottomPanel__totalSum'}>
                                    {t('Duration')}: {visit.duration} {t('minutes')} {this.checkDurationVisit() ? '' :
                                    <span
                                        className='important'> ({t('No more than')} {maxDurationMasterLoad} {t('minutes')})</span>}
                                </div>
                            </Col>
                            <Col md={6} className={'bottomPanel__buttonBlock'}>
                                <Button
                                    size={'lg'}
                                    variant="warning"
                                    className={'bottomPanel__button'}
                                    onClick={this.handleContinue}
                                    disabled={!this.checkDurationVisit()}
                                >
                                    {t('Continue')}
                                </Button>
                            </Col>
                        </Row>
                        : null
                }
            </Container>
        );
    }

    checkDurationVisit = () => {
        const {visit, maxDurationMasterLoad} = this.state;

        return visit.duration <= maxDurationMasterLoad;
    }

    filterFacilities = () => {
        const {facilities, visit, search} = this.state;
        const specializations = [];
        let guestsCount = null;
        let mastersCount = null;
        visit.visitFacility.forEach((visitFacility, idx) => {
            if (idx === 0) {
                guestsCount = visitFacility.guestsCount;
                mastersCount = visitFacility.mastersCount;
            }
            visitFacility.masterSpecializations.forEach(specialization => {
                if (specializations.indexOf(specialization) < 0) {
                    specializations.push(specialization);
                }
            });
        });

        let facilitiesDisplay = facilities.map(i => ({...i, renderPrice: [...i.renderPrice]}));

        facilitiesDisplay = facilitiesDisplay.filter((facility) => {

            if((this.props.getPerekrestok || this.props.getPsh) && this.state.visit.visitFacility.length >= 1) {
                return visit.visitFacility[0].id === facility.id
            }

            facility.renderPrice = facility.renderPrice.filter(price => {
                let result = true;

                if (visit.visitFacility.length > 0) {
                    result = !visit.visitFacility.find(visitFacility => price.id === visitFacility.priceId && visitFacility.facility === facility.id);

                    if (result) {
                        result = facility.masterSpecializations.some(specialization => specializations.indexOf(specialization) >= 0);
                    }
                }

                let regexp = new RegExp(search, 'i');

                if (result && search && price.name.search(regexp) === -1) {
                    result = false;
                }

                return result;
            })


            if (guestsCount !== null || mastersCount !== null) {
                if (guestsCount > mastersCount) {
                    return (facility.renderPrice.length > 0 && facility.guestsCount === guestsCount);
                }
                if (mastersCount > guestsCount) {
                    return (facility.renderPrice.length > 0 && facility.mastersCount === mastersCount);
                }
                if (mastersCount === guestsCount) {
                    return (facility.renderPrice.length > 0 && facility.mastersCount === mastersCount && facility.guestsCount === guestsCount);
                }
            }

            return facility.renderPrice.length > 0;
        })

        if(this.props.getPsh) {
            facilitiesDisplay = facilitiesDisplay.filter(facility => {
                return ['461f0bac-8ee7-4719-932c-8fd5c48b8244', '44880313-41ad-4bc1-9b05-7d157c4d6376', '27b589e2-5d8c-41b1-a665-a6915c7a6934', 'bfe51348-c5fe-46e5-a772-7240f83b3afa']
                    .includes(facility.id)
            })
        }

        this.setState({
            facilitiesDisplay: facilitiesDisplay
        }, () => {
            let queryParams = qs(this.props.location.search.substring(1));
            if (queryParams.facilityId) {
                const facility = facilitiesDisplay.find(facility => facility.id === queryParams.facilityId);
                if (facility && facility.ref.current) {
                    this.props.history.push(this.props.location.pathname);
                    window.scrollTo(0, facility.ref.current.offsetTop);
                }
            }
        })

    }

    handleChangeSearch = (event) => {
        this.setState({
            search: event.target.value
        }, this.filterFacilities)
    }

    handleContinue = () => {
        this.props.history.push('/calendar');
    }

    handleAddFacility = (facilityId, priceId) => () => {
        const {facilities} = this.state;

        let facility = facilities.find(facility => facility.id === facilityId);

        let price = facility ? facility.renderPrice.find(price => price.id === priceId) : null;

        if (price) {
            this.setState({
                visit: {
                    ...this.state.visit,
                    visitFacility: [
                        ...this.state.visit.visitFacility,
                        {
                            facility: facilityId,
                            duration: price.time,
                            price: price.widgetPrice,
                            priceId: price.id,
                            masterSpecializations: facility.masterSpecializations,
                            guestsCount: facility.guestsCount,
                            mastersCount: facility.mastersCount,
                        }
                    ]
                }
            }, () => {
                this.props.setVisitFacility(this.state.visit.visitFacility);
                this.filterFacilities();
                this.calculateVisit();
            })
        }
    }

    handleDeleteVisitFacility = (numberVisitFacility) => () => {
        let {visitFacility} = this.state.visit;

        visitFacility = visitFacility.filter((facility, number) => number !== numberVisitFacility);

        this.setState({
            visit: {
                ...this.state.visit,
                visitFacility: visitFacility
            }
        }, () => {
            this.props.setVisitFacility(this.state.visit.visitFacility);
            this.filterFacilities();
            this.calculateVisit();
        })
    }

    handleOpenFacility = (id) => () => {
        let {facilitiesDisplay} = this.state;

        facilitiesDisplay[id].informationBlockOpen = !facilitiesDisplay[id].informationBlockOpen;

        this.setState({
            facilitiesDisplay: facilitiesDisplay
        })
    }

    componentDidMount() {
        let {parlour} = this.props;

        let queryParams = qs(this.props.location.search.substring(1));

        if (!parlour || parlour.id == null) {
            this.props.history.push('/');
        } else {
            getFacilitiesVisit(parlour.id)
                .then(response => {
                    if (response.success) {
                        let facilities = response.data.map(facility => {
                            //TODO Здесь непонятно по каким условиям выбирать прайс если их будет несколько, поэтому пока берем первый прайс
                            let renderPrice = facility.prices.shift();
                            facility.renderPrice = null;

                            if (renderPrice && renderPrice.durations && Array.isArray(renderPrice.durations)) {
                                if( this.props.getPerekrestok || this.props.getPsh) {
                                    if(this.state.visit.visitFacility.length >= 1) return false;
                                    renderPrice.durations = renderPrice.durations.filter(price => {
                                        return price.time <= 0.5
                                    })
                                }
                                facility.renderPrice = renderPrice.durations.map(price => {

                                    if (renderPrice.priceCosmetic && renderPrice.priceCosmetic.val > 0) {
                                        price.price1.val += renderPrice.priceCosmetic.val;
                                        price.price2.val += renderPrice.priceCosmetic.val;
                                        price.price3.val += renderPrice.priceCosmetic.val;
                                        price.widgetPrice.val += renderPrice.priceCosmetic.val;
                                    }

                                    return {
                                        ...price,
                                        name: this.renderFacilityName(facility.name, price.time)
                                    }
                                })
                            }

                            return {
                                ...facility,
                                informationBlockOpen: queryParams.facilityId !== facility.id,
                                ref: React.createRef(),
                            }
                        });

                        let visitFacility = [];

                        if (this.props.visitFacility) {
                            visitFacility = this.props.visitFacility;
                        } else if (this.props.getFacility) {
                            // этот случай для выбора услуг с сайта
                            let facility = facilities.find((facility) => facility.id === this.props.getFacility);

                            if (facility) {
                                // на данном этапе не понятно какое время человек хочет поэтому берем 1 час, либо если час не предусмотрен, то первое возможное время
                                let price = facility.renderPrice.find(price => price.time === 1) || facility.renderPrice[0];

                                if (price) {
                                    visitFacility.push({
                                        facility: facility.id,
                                        duration: price.time,
                                        price: price.widgetPrice,
                                        priceId: price.id,
                                        masterSpecializations: facility.masterSpecializations,
                                        guestsCount: facility.guestsCount,
                                        mastersCount: facility.mastersCount,
                                    });
                                }
                            }
                        }
                        this.setState({
                            facilities: facilities,
                            facilitiesDisplay: facilities,
                            visit: {
                                visitFacility: visitFacility,
                            }
                        }, () => {
                            this.filterFacilities();
                            this.calculateVisit();
                        })
                    }
                })

            getMaxDurationMasterLoad()
                .then(response => {
                    if (response.success && response.data.maxDuration) {
                        this.setState({
                            maxDurationMasterLoad: response.data.maxDuration
                        })
                    }
                })
        }
    }

    renderFacilityName(name, time) {
        const {t} = this.props;

        return t(name) + ' ' + time * 60 + ' ' + t('minutes')
    }

    calculateVisit() {
        const {visit} = this.state;
        const visitParams = visit.visitFacility.reduce((result, itemFacility) => {
                return {sum: result.sum + itemFacility.price.val, duration: result.duration + itemFacility.duration}
            },
            {sum: 0, duration: 0}
        );

        visitParams.duration = moment.duration(visitParams.duration, 'hours').asMinutes();

        this.setState({
            visit: {
                ...visit,
                sum: visitParams.sum,
                duration: visitParams.duration
            }
        })
    }

    handleClickPsh = () => {
        this.props.setPsh(true)
        this.props.history.push('/')
    }

    handleClickNePsh = () => {
        this.props.setPsh(false)
        this.props.history.push('/')
    }
}

const mapStateToProps = state => ({
    parlour: state.visit.parlour,
    region: state.GET.region,
    visitFacility: state.visit.visitFacility,
    getFacility: state.GET.facility,
    getPsh: state.GET.psh,
    getPerekrestok: state.GET.perekrestok
});

const mapDispatchToProps = {
    setVisitFacility: visitAction.setVisitFacility,
    setPsh: getAction.setPsh,
    setPerekrestok: getAction.setPerekrestok
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(SelectFacilitiesPage)));
