import React from 'react';
import {Container, Row, Col} from "react-bootstrap";
import './selectParlour.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {withRouter} from "react-router";
import Header from '../common/header/Header';
import {connect} from 'react-redux';
import visitAction from '../../actions/visit-actions';
import {getParlours} from "../../services/rest";
import {withTranslation} from "react-i18next";

class SelectParlourPage extends React.Component {
    state = {
        parlours: [],
        parlour: null,
    }

    render() {
        const {parlours} = this.state;
        const {t} = this.props;

        return (
            <Container>
                <Header/>
                <Row className={'content'}>
                    <Col
                        md={{span:6, offset:3}}
                    >
                        <span className={'content__title'}>
                            {t('Select salon')}
                        </span>
                        <ul className={'parlourList'}>
                        {
                            parlours.map((parlour, id) => (
                                <li
                                    key={id}
                                    className={'parlourList__item parlourItem'}
                                    onClick={this.handleSelectParlour(parlour.id)}
                                >
                                    {
                                        parlour.coordinates && null
                                        ?
                                        <img
                                            className={'parlourItem__map'}
                                            src={'https://maps.googleapis.com/maps/api/staticmap?center=' + parlour.coordinates + '&key=AIzaSyDXdiemDvD0Id0YIR6EJhr-ZOgcYnRmnwA&language=ru&markers=color:green%7C' + parlour.coordinates + '&scale=2&size=454x170&style=feature:all&zoom=14'}
                                            alt=''
                                        />
                                        : null
                                    }
                                    <span className={'parlourItem__name'}>{parlour.displayName ? parlour.displayName : parlour.name}</span>
                                    <span className={'parlourItem__address'}>{parlour.address}</span>
                                    <FontAwesomeIcon
                                        icon={"chevron-right"}
                                        className={'parlourItem__arrowRight'}
                                    />

                                </li>
                                )
                            )
                        }
                        </ul>
                    </Col>
                </Row>
            </Container>
        );
    }

    componentDidMount() {
        let region = this.props.region || null;
        getParlours(region)
            .then(response => {
                if (response.success) {

                    //parlour auto select
                    let qs = require('query-string-object');
                    let queryParams = qs(this.props.location.search.substring(1));
                    if (queryParams.parlourId) {
                        let parlour = response.data.find( parlour => parlour.id === queryParams.parlourId);
                        this.props.setParlour(parlour);
                        let url = '/select-facilities';
                        if (queryParams.facilityId) {
                            url += '?facilityId='+queryParams.facilityId;
                        }
                        this.props.history.push(url);
                        return;
                    }

                    this.setState({
                        parlours: response.data
                    }, () => {
                        if(this.state.parlours.length === 1) {
                            let parlour = this.state.parlours.find( parlour => parlour.id === this.state.parlours[0].id);
                            this.props.setParlour(parlour);
                            this.props.history.push('/select-facilities');
                        }
                    })
                }
            })
    }

    handleSelectParlour = (id) => () => {
        const { parlours } = this.state;
        let parlour = parlours.find( parlour => parlour.id === id);

        this.props.setParlour(parlour);
        this.props.history.push('/select-facilities');

    }
}

const mapDispatchToProps = {
    setParlour: visitAction.setParlour
}

const mapStateToProps = state => ({
    region: state.GET.region,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(SelectParlourPage)));