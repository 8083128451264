import baseRequest from "./baseReuqest";
import * as queryString from "query-string-object";
import store from '../store';


const state = store.getState();
const API_KEY = process.env['REACT_APP_API_KEY' + state.GET.apiKeyNumber];

const HOST = process.env.REACT_APP_HOST;
const REGION = process.env.REACT_APP_REGION;

export function getFacilitiesVisit(parlour) {
    let url = HOST + '/online-order/facilitiesVisit';
    const q = queryString.stringify({parlour: parlour});

    if (q) {
        url += `?${q}`;
    }

    return baseRequest(url, {Authorization: 'ApiKey ' + API_KEY});
}

export function getTimingVisit(parlour, date, duration, specializations, facilities) {
    let durationFormat = duration * 60;

    let url = HOST + '/online-order/timingVisit';
    const q = queryString.stringify({parlour: parlour, date: date, duration: durationFormat, specializations, facilities:facilities});

    if (q) {
        url += `?${q}`;
    }

    return baseRequest(url, {Authorization: 'ApiKey ' + API_KEY})
}

export function createVisit(visit) {
    return baseRequest(HOST + '/online-order/visit', {
        method: 'POST',
        body: JSON.stringify(visit),
        Authorization: 'ApiKey ' + API_KEY
    });
}

export function updateVisit(visit) {
    return baseRequest( HOST + `/online-order/visit/${visit.id}`, {
        method: 'PUT',
        body: JSON.stringify(visit),
        Authorization: 'ApiKey ' + API_KEY
    })
}

export function getParlours(region) {
    let url = HOST + '/online-order/parlours';
    const q = queryString.stringify({search: {region: region || REGION}, page: 1, per_page: -1, order: 'asc', order_by: 'name'});

    if (q) {
        url += `?${q}`;
    }

    return baseRequest(url, {Authorization: 'ApiKey ' + API_KEY})
}

export function getReferralPartners(partner) {
    let url = HOST + '/online-order/referral-partner';
    const q = queryString.stringify({search: {name: partner}});

    if (q) {
        url += `?${q}`;
    }

    return baseRequest(url, {Authorization: 'ApiKey ' + API_KEY})
}

export function getMaxDurationMasterLoad() {
    return baseRequest(HOST + '/online-order/max-duration-master-load', {Authorization: 'ApiKey ' + API_KEY});
}

export function confirmationPhone(phone) {
    return baseRequest(HOST + '/online-order/confirm-phone/' + phone, {
        method: 'POST',
        Authorization: 'ApiKey ' + API_KEY
    });
}

export function checkCode(code, phone) {
    let url = HOST + '/online-order/check-phone-code';
    const q = queryString.stringify({code: code, phone: phone});

    return baseRequest(url + `?${q}`, {Authorization: 'ApiKey ' + API_KEY});
}

export function getDatesWithoutMasters(startPeriod, endPeriod, parlourId, perekrestok = false) {
    let url = HOST + '/online-order/dates-without-masters';

    const q = queryString.stringify({startPeriod, endPeriod, parlourId, perekrestok: perekrestok ? 1: false});

    return baseRequest(url + `?${q}`, {Authorization: 'ApiKey ' + API_KEY});
}

export function getDiscounts(visit) {
    return baseRequest( HOST + '/online-order/discounts' , {
        method: 'POST',
        body: JSON.stringify(visit),
        Authorization: 'ApiKey ' + API_KEY
    });
}

export function getGuestPyaDocs(id) {
    let url = HOST + `/online-order/get-guest-pay-docs/${id}`;
    return baseRequest(url, {Authorization: 'ApiKey ' + API_KEY});
}

export function payWithPayDoc(payDocId, guestId, visitId, discountId, total) {
    return baseRequest(  HOST + '/online-visits/payment' , {
        method: 'POST',
        body: JSON.stringify({
            payDoc: payDocId,
            guest: guestId,
            visit: visitId,
            discount: discountId,
            sum: total
        }),
        Authorization: 'ApiKey aRntGlNpc6BaHuysaB7XHG0Ergp1giaVODslS8jdiqx08wX4xx'
    });
}

export function payWithNewPayDoc(newPayDoc, guestId, visitId, discountId, sum) {
    return baseRequest( HOST + '/online-order/pay-with-new-pay-doc' , {
        method: 'POST',
        body: JSON.stringify({
            ...newPayDoc,
            guest: guestId,
            visit: visitId,
            discount: discountId,
            sum: sum
        }),
        Authorization: 'ApiKey aRntGlNpc6BaHuysaB7XHG0Ergp1giaVODslS8jdiqx08wX4xx'
    });
}

export function getDiscount(id) {
    let url = HOST + `/online-order/get-discount/${id}`;
    return baseRequest(url, {Authorization: 'ApiKey ' + API_KEY});
}

export function getGuest(id) {
    let url = HOST + `/online-order/get-guest/${id}`;
    return baseRequest(url, {Authorization: 'ApiKey ' + API_KEY});
}

export function checkFirstVisit(guest, region) {
    return baseRequest(  HOST + '/online-order/check-first-visit' , {
        method: 'POST',
        body: JSON.stringify({
            guest: guest,
            region: region,
        }),
        Authorization: 'ApiKey ' + API_KEY
    });
}

export function checkUnclosedVisits(phones, start, end) {
    let url = HOST + `/online-order/check-unclosed-visits`;
    return baseRequest(url, {
        query: {
            phones,
            start,
            end
        },
        Authorization: 'ApiKey ' + API_KEY
    });
}