import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from "react-bootstrap/Button";
import './unclosedVisitsCheck.scss';
import Header from "../common/header/Header";
import {connect} from 'react-redux';
import {checkUnclosedVisits} from '../../services/rest';
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";
import * as cn from 'classnames';
import moment from "moment";
import '../../styles/main.scss';

class UnclosedVisitsCheck extends React.Component {

    state = {
        unclosedVisitsCheck: {
            on_date: null,
            on_time: null
        },
        errorLoading: false
    }

    handleCreateRecord = () => {
        this.props.history.push('update-visit')
    }

    handleCancel = () => {
        this.props.history.push('/calendar');
    }

    fetchUnclosedVisitsCheck = () => {
        const { visit, guest } = this.props

        const lastFacility = visit.visitFacility[visit.visitFacility.length -1]
        const firstFacility = visit.visitFacility[0]

        const start = moment(firstFacility.start).utc().format()
        const end = moment(lastFacility.start).add(lastFacility.duration, 'minutes').utc().format()

        checkUnclosedVisits([guest.phone], start, end).then(
            (response) => {
                if (response.success) {
                    if (response.data.on_date || response.data.on_time ) {
                        this.setState({
                                unclosedVisitsCheck: response.data,
                                errorLoading: false
                            })
                    } else {
                        this.handleCreateRecord()
                    }
                } else {
                    this.setState({errorLoading: true})
                }
            }
        )
    }

    componentWillMount() {
        const { visit, guest } = this.props

        if (guest && guest.phone && visit && visit.id && visit.visitFacility) {
           this.fetchUnclosedVisitsCheck()
        } else {
          this.props.history.push('/')
        }
    }

    render() {
        const { t, getPsh} = this.props;
        const { unclosedVisitsCheck, errorLoading } = this.state

        return (
            <Container>
                <Header title={getPsh ? 'Записаться по акции "Плати сколько хочешь"' : null}/>
                {unclosedVisitsCheck.on_date != null || unclosedVisitsCheck.on_time != null || errorLoading ?
                    <Container>
                        <Row className={cn('content')}>
                            <Col md={{span:6, offset: 3}}>
                                <h1 className={'content__title'}>
                                    {unclosedVisitsCheck.on_date != null || unclosedVisitsCheck.on_time != null ?
                                        t('The guest has already been booked for the selected day'):
                                        t('Checking unclosed visits')}
                                </h1>
                            </Col>
                            <Col md={{span:6, offset: 3}} className={'text-center'}>
                                {errorLoading ?
                                    <p className={"important"}>
                                        {t('Failed to check')}
                                    </p>:
                                    null
                                }
                                {unclosedVisitsCheck.on_date ?
                                    unclosedVisitsCheck.on_time ?
                                        <p className={"important"}>
                                            {t('You cannot create a guest entry twice at the same time')}
                                        </p>:
                                        <p>
                                            {t('Create another entry?')}
                                        </p>:
                                    null
                                }
                            </Col>
                        </Row>
                        <Row className={cn('container', 'bottomPanel')}>
                            <Col xs={6} className={'bottomPanel__buttonBlock'}>
                                <Button
                                    size={'lg'}
                                    variant="info"
                                    className={'bottomPanel__button'}
                                    onClick={this.handleCancel}
                                >
                                    {t('Cancel')}
                                </Button>
                            </Col>
                            <Col xs={6} className={'bottomPanel__buttonBlock'}>
                                <Button
                                    size={'lg'}
                                    variant="warning"
                                    className={'bottomPanel__button'}
                                    onClick={this.handleCreateRecord}
                                    disabled={unclosedVisitsCheck.on_time}
                                >
                                    {t('Create record')}
                                </Button>
                            </Col>
                        </Row>
                    </Container>:
                    null
                }
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    visit: state.visit,
    getPsh: state.GET.psh,
    guest: state.GET.guest,
});

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(UnclosedVisitsCheck)));
