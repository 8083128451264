import React from 'react';
import Header from "../common/header/Header";
import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import {connect} from 'react-redux';
import Col from "react-bootstrap/Col";
import './selectPayDocPage.scss';
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";
import {getGuestPyaDocs, payWithNewPayDoc, payWithPayDoc} from "../../services/rest";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import * as PropTypes from "prop-types";
import InputMask from 'react-input-mask'
import visitAction from "../../actions/visit-actions";

class SelectPayDocPage extends React.Component {

    static propTypes = {
        visit: PropTypes.object,
        discount: PropTypes.string
    };

    state = {
        title: "Выберите сертификат или введите новый",
        payDocs: null,
        newPayDoc: null,
        error: null,
    }

    render() {
        const {newPayDoc, payDocs, error} = this.state;

        const formatChars = {
            '9': '[0-9]',
            'a': '[A-Za-zА-яа-я]',
            '*': '[A-Za-zА-яа-я0-9]'
        }

        return (
            <Container>
                <Header
                    title={this.state.title}
                />
                <Row>
                    <Col md={{span: 6, offset: 3}}>
                        {
                            error ? <div className={'errorText'}>{error}</div> : null
                        }

                        <h4>Ваши сертификаты:</h4>
                        <Row className={'justify-content-md-center'}>
                            {payDocs && payDocs.length > 0 ? payDocs.map((payDoc, index) =>
                                <Col md={{span: 4}} key={index}>
                                    <div className={'selectPayDocPage_payDoc'}
                                         onClick={this.handlePayPayDoc(payDoc.id)}>
                                        <div className={'selectPayDocPage_payDoc-num'}>{payDoc.num}</div>
                                        <div className={'selectPayDocPage_payDoc-balance'}>{payDoc.balanceText}</div>
                                    </div>
                                </Col>
                            ) : <h5 className={'text-center'}>Пусто</h5>}
                        </Row>

                        <Form className={'selectPayDocPage_newPayDocForm'}>
                            <h4>Активировать новый:</h4>
                            <InputMask
                                className={'selectPayDocPage_newPayDocForm-input form-control'}
                                value={(newPayDoc && newPayDoc.text) ? newPayDoc.text : ''}
                                placeholder={'AB000001#12345'}
                                onChange={this.handleChangeNewPayDoc}
                                mask="aa999999#*****"
                                formatChars={formatChars}
                            />
                            <Button
                                variant="warning"
                                className={'selectPayDocPage_newPayDocForm-btn'}
                                type="button"
                                disabled={!(newPayDoc && newPayDoc.num && newPayDoc.pin && this.props.visit.guest.id)}
                                block
                                onClick={this.handlePayNewPayDoc}
                            >
                                {'Отправить'}
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        )
    }

    componentDidMount() {
        if (!this.props.visit && !this.props.visit.id) {
            this.props.history.push('/');
        }

        getGuestPyaDocs(this.props.visit.guest.id)
            .then(response => {
                if (response.success) {
                    if (response.data.length > 0) {
                        this.setState({
                            payDocs: response.data,
                        })
                    } else {
                        this.setState({
                            title: 'У вас нет сертификата, введите новый',
                        })
                    }
                }
            })
    }

    handleChangeNewPayDoc = event => {
        let tmp = event.target.value.split('#');
        const num = tmp[0] || null;
        const pin = tmp[1] || null;
        this.setState({
            newPayDoc: {
                text: event.target.value,
                num: num,
                pin: pin,
            }
        })
    }

    handlePayNewPayDoc = () => {
        const {visit} = this.props;
        const {newPayDoc} = this.state;
        payWithNewPayDoc(newPayDoc, visit.guest.id, visit.id, visit.discountId, visit.total.val)
            .then(response => {
                if (response.success) {
                    this.setState({
                        test: response.data,
                    })
                    this.props.history.push('/success');
                } else {
                    this.setState({
                        error: response.error.message
                    })
                }
            })
    }

    handlePayPayDoc = (payDocId) => () => {
        const {visit} = this.props;
        payWithPayDoc(payDocId, visit.guest.id, visit.id, visit.discountId, visit.total.val)
            .then(response => {
                if (response.success) {
                    this.props.setVisit({...visit, total: response.data.total, totalFormatted: response.data.totalFormatted})
                    this.props.history.push('/success');
                } else {
                    this.setState({
                        error: response.error.code > 1000 ? response.error.message : 'Error'
                    })
                }
            })
    }
}

const mapStateToProps = state => ({
    visit: state.visit,
});

const mapDispatchToProps = {
    setVisit: visitAction.setVisit,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(SelectPayDocPage)));