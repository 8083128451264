import React from 'react';
import Header from "../common/header/Header";
import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import {monthText} from '../common/common';
import {connect} from 'react-redux';
import Col from "react-bootstrap/Col";
import * as PropTypes from "prop-types";
import './complete.scss';
import {withRouter} from "react-router";
import moment from 'moment';
import {withTranslation} from "react-i18next";
import {checkFirstVisit, getDiscount, getDiscounts} from "../../services/rest";
import visitAction from "../../actions/visit-actions";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ListGroup from "react-bootstrap/ListGroup";
import GETActions from "../../actions/GET-actions";

class CompletePage extends React.Component {
    static propTypes = {
        visit: PropTypes.object,
        parlour: PropTypes.object,
    };

    static defaultProps = {
        parlour: {
            address: null,
        }
    }

    state = {
        discounts: [],
        discount: null,
        prices: [],
        buttonLabel: 'Оплатить в салоне',
    }

    componentWillMount() {
        const {region, visit, partner} = this.props;

        checkFirstVisit(visit.guest.id, region)
            .then((response) => {
                if(response.data.result && region === 'ccfd1b52-d49e-4eb8-95c3-cbf98b473a57' && partner.found && !!partner.value) {
                    this.props.setDiscountId('6457e8e8-839c-46d4-8697-3abfdf38c8ed');
                    getDiscount('6457e8e8-839c-46d4-8697-3abfdf38c8ed')
                        .then(response => {
                            this.props.setDiscount(response.data)
                        })
                }
            })

    }

    defaultRender() {
        const {visit} = this.props;
        const {discounts, buttonLabel} = this.state;

        if (!visit.id) {
            return null;
        }

        const startVisit = moment(visit.visitFacility[0].start);
        const dateVisitText = startVisit ? startVisit.date() + ' ' + monthText[startVisit.month()].ru : null;
        const timeVisitText = startVisit ? startVisit.utcOffset(visit.parlour.timeZone).format('HH:mm') : null;

        return (
            <Container className={'facility__info facilityInfo'}>
                <Row className={'pb-3'}>
                    <Col className={'facilityInfo__blockDate'}>
                        <div className={'blockDate__date'}>{dateVisitText}</div>
                        <div className={'blockDate__time'}>{timeVisitText}</div>
                        <div className={'blockDate__address'}>{visit.parlour.address}</div>
                    </Col>
                    <Col className={'d-flex flex-column justify-content-center'}>
                        <div><b>Цена</b></div>
                        <div><b>{visit.totalFormatted}</b></div>
                    </Col>
                    <Col className={'facilityInfo__totalSum'}>
                        <span onClick={this.goSuccessPayParlour} className={'totalSum__link'}>
                            <span className={'totalSum_blockPrice'}>
                                <span className={'totalSum__text'}>{buttonLabel}</span>
                            </span>
                        </span>
                    </Col>
                </Row>
                {
                    discounts.length > 0 ? discounts.map((discount, index) =>
                        <Row key={index}>
                            <Col>
                                <div><b>Оплатить онлайн <br/> со скидкой {discount.discount.value}%</b></div>
                                <div><small>По акции
                                    "{discount.discount.title ?? discount.discount.name}"</small></div>
                                <div>Цена {discount.total.format}</div>
                            </Col>
                            <Col className={'facilityInfo__totalSum'}>
                                <span
                                    onClick={this.handleSelectPayWithPayDoc(discount.discount.id, discount.total.val)}
                                    target='_top'
                                    className={'totalSum__link'}
                                >
                                    <span className={'totalSum_blockPrice'}>
                                        <FontAwesomeIcon icon={'file-invoice-dollar'}/>
                                        <span className={'totalSum__text'}>Оплатить сертификатом</span>
                                    </span>
                                </span>
                            </Col>
                            <Col className={'facilityInfo__totalSum'}>
                                <span
                                    onClick={this.handleCashlessPay(discount.discount.id)}
                                    target='_top'
                                    className={'totalSum__link'}
                                >
                                    <span className={'totalSum_blockPrice'}>
                                        <FontAwesomeIcon icon={'credit-card'}/>
                                        <span className={'totalSum__text'}>Оплата картой</span>
                                    </span>
                                </span>
                            </Col>
                        </Row>
                    ) : null
                }
            </Container>
        )
    }

    render() {
        const {visit, t, region, discount} = this.props;

        if (!visit.id) {
            return null;
        }

        const startVisit = moment(visit.visitFacility[0].start);
        const dateVisitText = startVisit ? startVisit.date() + ' ' + monthText[startVisit.month()].ru : null;
        const timeVisitText = startVisit ? startVisit.utcOffset(visit.parlour.timeZone).format('HH:mm') : null;

        return (
            <Container>
                <Header
                    title={t('Successful registration')}
                />
                <Row>
                    <Col md={{span: 6, offset: 3}} className={'facilitiesVisit__list'}>
                        <h4 className={'text-center text-uppercase font-weight-bold my-3'}>Заказ оформлен</h4>
                        {
                            visit && visit.visitFacility
                                ? <ListGroup variant="flush">
                                    {
                                        visit.visitFacility.map((visitFacility, index) => {
                                            return (
                                                <ListGroup.Item key={index} className={'facilitiesVisit__item facility'}>
                                                    {index + 1}. {t(visitFacility.facility.name)}
                                                </ListGroup.Item>
                                            )
                                        })
                                    }
                                </ListGroup>
                                : null
                        }
                        {region === 'ccfd1b52-d49e-4eb8-95c3-cbf98b473a57' ?
                        <Container className={'facility__info facilityInfo'}>
                            <Row className={'pb-3'}>
                                <Col className={'facilityInfo__blockDate'}>
                                    <div className={'blockDate__date'}>{dateVisitText}</div>
                                    <div className={'blockDate__time'}>{timeVisitText}</div>
                                    <div className={'blockDate__address'}>{visit.parlour.address}</div>
                                </Col>
                                <Col className={'d-flex flex-column justify-content-center'}>
                                    <div><b>Цена</b></div>
                                    <div><b>{discount ? visit.total.val-visit.total.val/100*10 : visit.totalFormatted}</b></div>
                                </Col>
                            </Row>
                                <Row>
                                    <Col className={'facilityInfo__totalSum'}>
                                        <span
                                            onClick={this.handleCashlessPay(discount? discount.id :null, discount ? (visit.total.val-visit.total.val/100*10)/2 : visit.total.val/2)}
                                            target='_top'
                                            className={'totalSum__link'}
                                        >
                                            <span className={'totalSum_blockPrice'}>
                                                <FontAwesomeIcon icon={'credit-card'}/>
                                                <span className={'totalSum__text'}>Предоплата 50%</span>
                                            </span>
                                        </span>
                                    </Col>
                                    <Col className={'facilityInfo__totalSum'}>
                                        <span
                                            onClick={this.handleCashlessPay(discount? discount.id :null, discount ? visit.total.val-visit.total.val/100*10 : visit.total.val)}
                                            target='_top'
                                            className={'totalSum__link'}
                                        >
                                            <span className={'totalSum_blockPrice'}>
                                                <FontAwesomeIcon icon={'credit-card'}/>
                                                <span className={'totalSum__text'}>Оплатить полностью</span>
                                            </span>
                                        </span>
                                    </Col>

                                </Row>
                        </Container>
                            : this.defaultRender()
                        }
                    </Col>
                </Row>
            </Container>
        )
    }

    componentDidMount() {
        if (!this.props.visit || !this.props.visit.id) {
            this.props.history.push('/');
        }

        const {visit} = this.props;

        getDiscounts({
            ...this.processVisit(visit)
        })
            .then(response => {
                if (response.success) {
                    response.data.map(d => {
                        if (d.discount.id === '6457e8e8-839c-46d4-8697-3abfdf38c8ed') {
                            this.setState({
                                buttonLabel: 'Оплатить без скидки в салоне',
                            })
                        }
                        return 0;
                    });
                    this.setState({
                        discounts: response.data,
                    })
                }
            })
    }

    goSuccessPayParlour = () => {
        this.props.history.push('/success-pay-parlour');
    }

    processVisit = (visit) => {
        let tmpVisit = JSON.parse(JSON.stringify(visit));
        tmpVisit.guest = tmpVisit.guest.id;
        tmpVisit.parlour = tmpVisit.parlour.id;
        tmpVisit.visitFacility.forEach((vf) => {
            vf.facility = vf.facility.id
        })
        return tmpVisit;
    }

    setLinkPay = (discountId = null, price = null) => {
        const {visit, region} = this.props;
        const {discounts} = this.state;

        if(!price) {
            price = visit.total.val;
        }

        if (discounts.length > 0 && discountId) {
            let discount = discounts.find((d) => {
                return d.discount.id === discountId
            });
            price = discount.total.val;
        }



        let linkPay = '';
        let params = [
            'from_type',
            'user_name',
            'user_phone',
            'free_price',
            'user_email',
            'visit',
            'discount'
        ];
        const defaultValueLink = '?';

        if (region && region !== process.env.REACT_APP_CZECH_REGION) {
            linkPay = params.reduce((itemLink, param) => {
                let result = itemLink === defaultValueLink ? itemLink : itemLink + '&';

                switch (param) {
                    case 'from_type':
                        result += param + '=visit'
                        break;

                    case 'user_name':
                        result += param + '=' + visit.guest.fullName;
                        break;

                    case 'user_phone':
                        result += param + '=' + visit.guest.phone;
                        break;

                    case 'user_email':
                        result += param + '=' + visit.guest.email;
                        break;

                    case 'free_price':
                        result += param + '=' + price;
                        break;

                    case 'visit':
                        result += param + '=' + visit.id;
                        break;

                    case 'discount':
                        result += param + '=' + discountId;
                        break;

                    default:
                        break;
                }

                return result;
            }, defaultValueLink);
        }

        return linkPay;
    }

    handleSelectPayWithPayDoc = (discount, total) => event => {
        this.props.setDiscountId(discount);
        this.props.setTotal(total);
        this.props.history.push('/select-pay-doc')
    }

    handleCashlessPay = (id =null, price = null) => event => {
        const {region} = this.props;
        if (region && region !== process.env.REACT_APP_CZECH_REGION) {
            window.open(process.env.REACT_APP_HOST_PAY + this.setLinkPay(id, price))
            this.props.history.push('/success')
        }
    }
}

const mapStateToProps = state => ({
    visit: state.visit,
    region: state.GET.region,
    discount: state.GET.discount,
    partner: state.GET.partner
});

const mapDispatchToProps = {
    setVisit: visitAction.setVisit,
    setDiscountId: visitAction.setDiscountId,
    setTotal: visitAction.setTotal,
    setDiscount: GETActions.setDiscount
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(CompletePage)));
