export const monthText = [
    {
      ru: 'января',
    },
    {
      ru: 'февраля',
    },
    {
      ru: 'марта',
    },
    {
        ru: 'апреля',
    },
    {
        ru: 'мая',
    },
    {
        ru: 'июня',
    },
    {
        ru: 'июля',
    },
    {
        ru: 'августа',
    },
    {
        ru: 'сентября',
    },
    {
        ru: 'октября',
    },
    {
        ru: 'ноября',
    },
    {
        ru: 'декабря',
    }
];

export const currency = {
    RUB: 'руб',
    CZK: 'Kč',
    EUR: 'eur',
    USD: 'usd'
};