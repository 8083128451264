import React from 'react';
import {Switch, Route, withRouter} from 'react-router';
import SelectParlourPage from "./pages/selectParlour/SelectParlourPage";
import Calendar from "./pages/calendar/CalendarPage";
import TimingVisitPage from "./pages/timingVisit/TimingVisitPage";
import RegistrationPage from "./pages/registration/RegistrationPage";
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faChevronRight,
    faChevronDown,
    faChevronUp,
    faTimes,
    faArrowLeft,
    faFileInvoiceDollar,
    faCreditCard,
    faCalendar,
    faClock,
    faMapMarker
    } from '@fortawesome/free-solid-svg-icons';
import SelectFacilitiesPage from "./pages/selectFacilities/SelectFacilitiesPage";
import CompletePage from "./pages/complete/CompletePage";
import {connect} from "react-redux";
import regionAction from "./actions/GET-actions";
import SelectPayDocPage from "./pages/selectPayDoc/SelectPayDocPage";
import SuccessPage from "./pages/successPage/SuccessPage";
import SuccessPayParlourPage from "./pages/successPayParlourPage/successPayParlourPage"
import SelectPaymentPage from "./pages/selectPayment/SelectPaymentPage"
import {getDiscount, getGuest} from "./services/rest";
import UnclosedVisitsCheck from "./pages/unclosedVisitsCheck/UnclosedVisitsCheck";
import UpdateVisit from "./pages/updateVisit/updateVisit"

library.add(faChevronRight);
library.add(faChevronDown);
library.add(faChevronUp);
library.add(faTimes);
library.add(faArrowLeft);
library.add(faFileInvoiceDollar);
library.add(faCreditCard);
library.add(faCalendar);
library.add(faClock);
library.add(faMapMarker);

class App extends React.Component {
    state = {
        GET:{},
    }

    render() {
        return (
            <Switch>
                <Route exact path="/" component={SelectParlourPage}/>
                <Route path='/select-facilities' component={SelectFacilitiesPage}/>
                <Route path={'/calendar'} component={Calendar}/>
                <Route path={'/timing-visit'} component={TimingVisitPage}/>
                <Route path={'/registration'} component={RegistrationPage}/>
                <Route path={"/check-unclosed-visits"} component={UnclosedVisitsCheck}/>
                <Route path={"/update-visit"} component={UpdateVisit}/>
                <Route path={'/complete'} component={CompletePage}/>
                <Route path={'/select-pay-doc'} component={SelectPayDocPage}/>
                <Route path={'/success'} component={SuccessPage}/>
                <Route path={'/success-pay-parlour'} component={SuccessPayParlourPage}/>
                <Route path={'/select-payment'} component={SelectPaymentPage}/>
            </Switch>
        );
    }

    componentDidMount() {
        this.getGETParameters();
    }

    getGETParameters = () => {
        let GetParams = this.props.location.search.replace('?','')
            .split('&')
            .reduce(
                (result, param) => {
                    let operand = param.split('=');

                    if (operand[0]) {
                        result[decodeURIComponent(operand[0])] = decodeURIComponent(operand[1]);
                    }

                    return result;
                },
                {}
            );

        if (GetParams.region) {
            this.props.setRegion(GetParams.region);
        }

        if (GetParams.facility) {
            this.props.setFacility(GetParams.facility)
        }

        if (GetParams.psh) {
            this.props.setPsh(true)
        }

        if (GetParams.perekrestok) {
            this.props.setPerekrestok(true)
        }

        if (GetParams.discount) {
            getDiscount(GetParams.discount)
                .then(response => {
                    this.props.setDiscount(response.data)
                })
        }

        if (GetParams.guestId) {
            getGuest(GetParams.guestId)
                .then(response => {
                    this.props.setGuest(response.data)
                })
        }

        if(GetParams.utm_source) {
            this.props.setUtmSource(GetParams.utm_source)
        }
        if(GetParams.utm_medium) {
            this.props.setUtmMedium(GetParams.utm_medium)
        }
        if(GetParams.utm_campaign) {
            this.props.setUtmCampaign(GetParams.utm_campaign)
        }
        if(GetParams.utm_term) {
            this.props.setUtmTerm(GetParams.utm_term)
        }
        if(GetParams.utm_content) {
            this.props.setUtmContent(GetParams.utm_content)
        }
    }
}

const mapDispatchToProps = {
    setRegion: regionAction.setRegion,
    setFacility: regionAction.setFacility,
    setPsh: regionAction.setPsh,
    setPerekrestok: regionAction.setPerekrestok,
    setDiscount: regionAction.setDiscount,
    setGuest: regionAction.setGuest,
    setUtmSource: regionAction.setUtmSource,
    setUtmMedium: regionAction.setUtmMedium,
    setUtmCampaign: regionAction.setUtmCampaign,
    setUtmTerm: regionAction.setUtmTerm,
    setUtmContent: regionAction.setUtmContent,
}

export default connect(null, mapDispatchToProps)(withRouter(App));
