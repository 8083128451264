import i18n from "i18next";
import XHR from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import availableLanguages from './available-languages';
// import moment from './moment';
// import moment from "moment";
// import('moment/locale/ru');
// moment.locale('ru');

// const localeMap = {
//     ru: 'ru',
//     en: 'en-gb',
//     cs: 'cs'
// };

i18n.on('initialized', options => {
    // if (i18n.language === 'ru') {
    //     import('moment/locale/ru');
    // } else if (i18n.language === 'cs') {
    //     import('moment/locale/cs');
    // }
    // moment.locale(localeMap[i18n.language]);
    // console.log(i18n.language);
});

i18n
    .use(XHR)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        // backend: {
        //   loadPath:
        //     `/locales/{{lng}}/{{ns}}.json`
        // },
        fallbackLng: "ru",
        load: "languageOnly",
        //debug: true,
        whitelist: availableLanguages,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        detection: {
            caches: ['localStorage', 'cookie'],
            cookieMinutes: 300,
        }
    });

i18n.on('languageChanged', function(lng) {
    // if (lng === 'ru') {
    //     import('moment/locale/ru');
    // } else if (lng === 'cs') {
    //     import('moment/locale/cs');
    // }
    // moment.locale(localeMap[lng]);

    // console.log("detector", lng);
});

export default i18n;